import React from "react"
import { LazyLoadImage } from "react-lazy-load-image-component"
import canditate1 from "../../images/recruitment.png"
import canditate2 from "../../images/group2.png"
import { Row, Col } from "reactstrap"
import { Link } from "gatsby"
const Find = () => {
  return (
    <div className="overlay-icons">
      <Row className="btf-wrap justify-content-center">
        <Col md={5} className="text-center my-3">
          <div className="header-icons">
            <Link to="/jobs">
              <LazyLoadImage
                className="mt-3"
                src={canditate2}
                alt="right to hire"
              />
              <div className="mt-4 mb-3">
                <h4>Find a Job</h4>
              </div>
            </Link>
          </div>
        </Col>
        <Col md={5} className="text-center my-3">
          <div className="header-icons">
            <Link to="/recruiting-services">
              <LazyLoadImage
                className="mt-3"
                src={canditate1}
                alt="direct hire"
              />
              <div className="mt-4 mb-3">
                <h4>Find a Candidate</h4>
              </div>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Find
